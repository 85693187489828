import React, {useEffect, useState} from "react";
import {httpRequestWithAuth} from "../../../global/axios_instance";
import {ProductsURL} from "../../../global/urls";
import {isValidValue, openNotification, showConfirm, showError} from "../../../global/helper_functions";
import {Col, Row} from "antd";
import {ActionButton} from "../../../global/components/ActionButton";
import {CustomTextField} from "../../../global/components/fields/CustomTextField";
import {permission_data} from "../../../global/permissions_data";
import {checkPermission} from "../../../global/permissions";
import {CustomTextAreaField} from "../../../global/components/fields/CustomTextAreaField";
import Hide from "../../../global/components/Hide";
import {VendorAutoComplete} from "../../../global/components/fields/VendorAutoComplete";
import {Loading} from "../../../global/components/Loading";
import {getUserVendorId} from "../../../global/auth_functions";
import CustomCheckBox from "../../../global/components/fields/CustomCheckBox";
import {CustomMultiImageUploader} from "../../../global/components/CustomMultiImageUploader";
import {ProductCategoriesAutoComplete} from "../../../global/components/fields/ProductCategoriesAutoComplete";
import {VendorBrandAutoComplete} from "../../../global/components/fields/VendorBrandAutoComplete";
import {CustomPhoneNumberField} from "../../../global/components/fields/CustomPhoneNumberField";
import VendorCategoryAutoComplete from "../../../global/components/fields/VendorCategoryAutoComplete";
import Cookies from "js-cookie";
// import {ProductSubCategoriesAutoComplete} from "../../../global/components/fields/ProductSubCategoriesAutoComplete";

export const ProductDetails = props => {
    const is_new = props.is_new;
    const product_id = props.product_id || "";
    const slug = props.slug || "";
    const user_type = props.user_type || "";
    const is_vendor = props.is_vendor || false;

    const [has_perm] = useState(checkPermission(permission_data.product.edit) || (product_id === "new" && checkPermission(permission_data.product.add)));
    const [data, setData] = useState({
        VendorId: is_vendor ? getUserVendorId() : null,
        product_categories_id: [],
        is_active: true,
        image_list: []
    });

    const [loading, setLoading] = useState(true);
    const [helper_text, setHelperText] = useState({});


    useEffect(() => {
        if (!is_new) {
            fetchData();
        } else {
            setLoading(false);
        }
    }, [])

    const fetchData = async () => {
        let response = await httpRequestWithAuth(`${ProductsURL}${product_id}`, "GET");
        if (response.status) {
            setData(response.data);
            props.setProductData(response.data);
        } else {
            showError("Get Product Data", response.error_feedback.non_field_errors);
            onCancel();
        }
        setLoading(false);
    }


    const handleFieldChange = (name, value) => {
        let temp_data = {...data};
        temp_data[name] = value;

        if (name === "VendorId") {
            temp_data.product_categories_id = [];
        }

        setData(temp_data);
    }


    const handleAddImage = (name, images) => {
        let temp_data = {...data};

        temp_data.image_list = images
        setData(temp_data);
    }


    const handleRemoveImage = (name, value, index) => {
        let temp_data = {...data};
        temp_data[name] = value;

        let image_list = temp_data.image_list || [];
        image_list.splice(index, 1);
        temp_data.image_list = image_list
        setData(temp_data);
    }

    const onCancel = () => {
        props.history.push(`/${slug}/products`);
    }


    const onDelete = async () => {
        await setLoading(true);
        let response = await httpRequestWithAuth(`${ProductsURL}${data.id}`, "DELETE");
        if (response.status) {
            openNotification("success", "Delete Product", `${data.name_en} product has been deleted`)
            onCancel();
        } else {
            openNotification("error", "Delete Product", response.error_feedback.non_field_errors)
        }
        await setLoading(false);
    }


    const handleDelete = () => {
        Cookies.remove("products_open_in_page")
        showConfirm("Delete Product", `Are you sure to delete ${data.name_en} product ?`, onDelete);
    }


    const onSave = async () => {
        await setLoading(true);
        setHelperText({});
        let temp_data = {...data}

        for (let i = 1; i <= 5; i++) {

            if (temp_data.image_list.length >= i) {
                temp_data[`image_${i}`] = temp_data.image_list[i - 1].value;
            } else {
                temp_data[`image_${i}`] = "";
            }
        }

        const form_data = new FormData();
        Object.keys(temp_data).map(data_key => form_data.append(data_key, temp_data[data_key]));

        let response;

        if (is_new) {
            response = await httpRequestWithAuth(ProductsURL, "POST", form_data);
        } else {
            response = await httpRequestWithAuth(`${ProductsURL}${data.id}`, "PUT", form_data);
        }

        if (response.status) {
            props.setProductData(response.data);
            if (is_new) {
                openNotification("success", "Add Product", `${data.name_en} product has been added`);
            } else {
                openNotification("success", "Edit Product", response.data.msg || `${data.name_en} product has been updated`);
            }


            onCancel();


        } else {
            // openNotification("error", is_new?"Add Product":"Edit Product", response.error_feedback.non_field_errors)
            setHelperText(response.error_feedback)
        }


        setLoading(false);
    }


    if (loading) {
        return <Loading/>
    }


    return (
        <>
            <Row gutter={[12, 10]}>

                <Col xs={24}>
                    <h2 className="font-size-22 mb-0">
                        <label className="text-start">
                            Details
                        </label>
                    </h2>
                </Col>


                <Col xs={24}>
                    <CustomMultiImageUploader
                        with_crop
                        name="image"
                        label="Add Product Images"
                        image_list={data.image_list}
                        onAdd={handleAddImage}
                        onRemove={handleRemoveImage}
                        max_upload={5}
                        error={helper_text.image_1 !== undefined}
                        helper_text={helper_text.image_1}
                    />
                </Col>

                <Hide hide={is_vendor}>
                    <Col xs={24} md={6}>
                        <VendorAutoComplete
                            view_only={!has_perm}
                            label="Vendor"
                            name="VendorId"
                            value={data.VendorId}
                            onChange={handleFieldChange}
                            error={helper_text.VendorId !== undefined}
                            helper_text={helper_text.VendorId}
                        />
                    </Col>
                </Hide>


                <Col xs={24} md={is_vendor ? 8 : 6}>
                    <CustomTextField
                        view_only={!has_perm}
                        label="Name"
                        name="name_en"
                        value={data.name_en}
                        onChange={handleFieldChange}
                        error={helper_text.name_en !== undefined}
                        helper_text={helper_text.name_en}
                    />
                </Col>


                {/*<Col xs={24} md={is_vendor ? 8 : 6}>*/}
                {/*    <CustomTextField*/}
                {/*        view_only={!has_perm}*/}
                {/*        label="Name Ar"*/}
                {/*        name="name_ar"*/}
                {/*        value={data.name_ar}*/}
                {/*        onChange={handleFieldChange}*/}
                {/*        error={helper_text.name_ar !== undefined}*/}
                {/*        helper_text={helper_text.name_ar}*/}
                {/*    />*/}
                {/*</Col>*/}


                <Col xs={24} md={is_vendor ? 8 : 6}>
                    <CustomTextField
                        view_only={!has_perm}
                        label="SKU"
                        name="sku"
                        value={data.sku}
                        onChange={handleFieldChange}
                        error={helper_text.sku !== undefined}
                        helper_text={helper_text.sku}
                    />
                </Col>



                <Col xs={24} md={is_vendor ? 8 : 6}>
                    <VendorBrandAutoComplete
                        vendor={data.VendorId}
                        view_only={!has_perm}
                        label="Brand"
                        name="VendorBrandId"
                        value={data.VendorBrandId}
                        onChange={handleFieldChange}
                        error={helper_text.VendorBrandId !== undefined}
                        helper_text={helper_text.VendorBrandId}
                    />
                </Col>

                <Col xs={24} md={121}>
                    <VendorCategoryAutoComplete
                        // select_first
                        vendor={data.VendorId}
                        view_only={!has_perm}
                        label="Vendor Category"
                        name="VendorCategoryId"
                        value={data.VendorCategoryId || ""}
                        onChange={handleFieldChange}
                        error={helper_text.VendorCategoryId !== undefined}
                        helper_text={helper_text.VendorCategoryId}
                    />
                </Col>

                <Col xs={24}>
                    <ProductCategoriesAutoComplete
                        // select_first
                        multiple
                        vendor={data.VendorId || "null"}
                        vendor_category={data.VendorCategoryId || "null"}
                        view_only={!has_perm}
                        label="Product Categories"
                        name="product_categories_id"
                        value={data.product_categories_id || []}
                        onChange={handleFieldChange}
                        error={helper_text.product_categories_id !== undefined}
                        helper_text={helper_text.product_categories_id}
                        // getFullObj={getProductCategoryObj}
                    />
                </Col>



                <Col xs={24} md={6}>
                    <CustomTextField
                        disabled={data.has_sizes}
                        view_only={!has_perm}
                        label="Total Qty"
                        name="qty"
                        value={data.qty}
                        onChange={handleFieldChange}
                        error={helper_text.qty !== undefined}
                        helper_text={helper_text.qty}
                    />
                </Col>


                <Col xs={24} md={6}>
                    <CustomTextField
                        view_only={!has_perm}
                        label="Price"
                        name="price"
                        value={data.price}
                        onChange={handleFieldChange}
                        error={helper_text.price !== undefined}
                        helper_text={helper_text.price}
                        addonAfter="KWD"
                    />
                </Col>

                <Col xs={24} md={6}>
                    <CustomTextField
                        view_only={!has_perm}
                        label="Minimum delivery days"
                        name="minimum_delivery_days"
                        value={data.minimum_delivery_days}
                        onChange={handleFieldChange}
                        error={helper_text.minimum_delivery_days !== undefined}
                        helper_text={helper_text.minimum_delivery_days}
                    />
                </Col>

                <Col xs={24} md={6}>
                    <CustomTextField
                        view_only={!has_perm}
                        label="Maximum delivery days"
                        name="maximum_delivery_days"
                        value={data.maximum_delivery_days}
                        onChange={handleFieldChange}
                        error={helper_text.maximum_delivery_days !== undefined}
                        helper_text={helper_text.maximum_delivery_days}
                    />
                </Col>


                <Col xs={24}>
                    <CustomTextAreaField
                        view_only={!has_perm}
                        label="Description"
                        name="description_en"
                        value={data.description_en}
                        onChange={handleFieldChange}
                        error={helper_text.description_en !== undefined}
                        helper_text={helper_text.description_en}
                    />
                </Col>


                {/*<Col xs={24}>*/}
                {/*    <CustomTextAreaField*/}
                {/*        view_only={!has_perm}*/}
                {/*        label="Description Ar"*/}
                {/*        name="description_ar"*/}
                {/*        value={data.description_ar}*/}
                {/*        onChange={handleFieldChange}*/}
                {/*        error={helper_text.description_ar !== undefined}*/}
                {/*        helper_text={helper_text.description_ar}*/}
                {/*    />*/}
                {/*</Col>*/}


                <Col xs={24} md={12}>
                    <CustomTextField
                        view_only={!has_perm}
                        disabled={!data.has_discount}
                        label="Price After Discount"
                        name="price_after_discount"
                        value={data.price_after_discount}
                        onChange={handleFieldChange}
                        error={helper_text.price_after_discount !== undefined}
                        helper_text={helper_text.price_after_discount}
                        addonAfter="KWD"
                    />
                </Col>

                <Hide data={is_vendor}>
                    <Col xs={24} md={12}>
                        <CustomPhoneNumberField
                            view_only={!has_perm}
                            disabled={!data.is_call_for_price}
                            label="Contact Number For Price"
                            name="contact_number_for_price"
                            value={data.contact_number_for_price}
                            onChange={handleFieldChange}
                            error={helper_text.contact_number_for_price !== undefined}
                            helper_text={helper_text.contact_number_for_price}
                        />
                    </Col>
                </Hide>

                <Col xs={24} className="mt-2"/>


                <Col xs={24} md={5}>
                    <CustomCheckBox
                        view_only={!has_perm}
                        label="Is Active"
                        name="is_active"
                        value={data.is_active}
                        onChange={handleFieldChange}
                        error={helper_text.is_active !== undefined}
                        helper_text={helper_text.is_active}
                    />
                </Col>

                <Col xs={24} md={5}>
                    <CustomCheckBox
                        view_only={!has_perm}
                        label="Has Discount"
                        name="has_discount"
                        value={data.has_discount}
                        onChange={handleFieldChange}
                        error={helper_text.has_discount !== undefined}
                        helper_text={helper_text.has_discount}
                    />
                </Col>

                <Hide data={is_vendor}>
                    <Col xs={24} md={5}>
                        <CustomCheckBox
                            view_only={!has_perm}
                            label="Is Call For Price"
                            name="is_call_for_price"
                            value={data.is_call_for_price}
                            onChange={handleFieldChange}
                            error={helper_text.is_call_for_price !== undefined}
                            helper_text={helper_text.is_call_for_price}
                        />
                    </Col>
                </Hide>

                <Col xs={24} md={5}>
                    <CustomCheckBox
                        view_only={!has_perm}
                        label="Is Sold Out"
                        name="is_sold_out"
                        value={data.is_sold_out}
                        onChange={handleFieldChange}
                        error={helper_text.is_sold_out !== undefined}
                        helper_text={helper_text.is_sold_out}
                    />
                </Col>


                <Col xs={24} md={4}>
                    <CustomCheckBox
                        view_only={!has_perm}
                        label="Has Sizes"
                        name="has_sizes"
                        value={data.has_sizes}
                        onChange={handleFieldChange}
                        error={helper_text.has_sizes !== undefined}
                        helper_text={helper_text.has_sizes}
                    />
                </Col>


                <Col xs={24}>
                    <ActionButton
                        error_msg={helper_text.non_field_errors}
                        onDelete={handleDelete}
                        onCancel={onCancel}
                        allow_delete={isValidValue(data.id) && checkPermission(permission_data.product.delete)}
                        allow_save={has_perm}
                        onSave={onSave}
                    />
                </Col>
            </Row>
        </>

    )
}
